import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
	disabledLink: PropTypes.bool,
	sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
	const theme = useTheme();
	const PRIMARY_LIGHT = theme.palette.primary.light;
	const PRIMARY_MAIN = theme.palette.primary.main;
	const PRIMARY_DARK = theme.palette.primary.dark;
	const logo = (
		<Box sx={{ width: 40, height: 40, ...sx }}>
			<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 1000.000000 1000.000000">
				<defs>
					<linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
						<stop offset="0%" stopColor={PRIMARY_DARK} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
				</defs>
				<g
					fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd" transform="translate(0.000000,1000.000000) scale(0.100000,-0.100000)">
					<path d="M6215 6863 c-449 -60 -848 -307 -1098 -681 -70 -105 -136 -237 -175
-352 -60 -173 -72 -249 -82 -490 -7 -167 -13 -223 -34 -302 -75 -292 -275
-542 -546 -682 -208 -107 -448 -145 -660 -104 -30 5 -58 12 -63 15 -10 6 35
65 118 153 39 41 100 106 136 145 35 38 66 72 69 75 8 7 318 342 365 394 54
59 410 443 448 482 47 49 50 56 72 160 43 200 113 377 207 526 16 26 28 52 26
57 -6 17 -111 141 -120 141 -4 0 -8 6 -8 13 0 8 -11 20 -25 27 -14 7 -25 19
-25 27 0 7 -7 13 -15 13 -8 0 -25 10 -39 23 -28 26 -39 34 -88 67 -21 14 -38
28 -38 33 0 4 -4 7 -9 7 -4 0 -47 23 -95 50 -48 28 -90 50 -94 50 -4 0 -24 9
-43 19 -37 20 -176 69 -209 74 -11 2 -23 6 -26 10 -4 4 -20 7 -35 7 -15 0 -40
5 -56 11 -15 6 -55 13 -88 14 -33 2 -98 7 -145 10 -143 11 -388 -25 -557 -81
-140 -46 -299 -133 -438 -238 -591 -447 -775 -1265 -434 -1931 74 -145 148
-245 284 -381 229 -230 462 -356 785 -425 143 -31 426 -33 575 -5 315 59 599
209 825 435 120 121 240 293 304 436 9 22 21 49 26 60 60 133 112 373 111 506
-2 113 16 300 35 376 42 173 142 343 283 483 116 115 210 180 343 236 237 100
523 111 767 28 190 -65 385 -200 494 -343 23 -31 45 -57 50 -59 4 -2 7 -9 7
-15 0 -7 8 -22 18 -35 l17 -22 55 32 c30 18 117 69 193 112 77 44 137 85 137
93 0 23 -96 160 -168 241 -69 78 -200 192 -297 259 -79 54 -264 146 -340 169
-33 10 -62 21 -65 25 -5 6 -87 25 -150 34 -136 21 -181 24 -310 23 -80 0 -158
-3 -175 -5z m-2259 -484 c208 -37 393 -128 569 -282 l40 -34 -60 -65 c-33 -35
-170 -183 -305 -328 -135 -145 -301 -325 -370 -399 -69 -74 -161 -173 -205
-221 -108 -118 -439 -474 -492 -530 -34 -35 -45 -42 -52 -31 -5 8 -7 20 -4 28
3 8 -2 13 -15 13 -11 0 -23 8 -27 18 -3 11 -17 24 -30 30 -14 6 -25 18 -25 27
0 8 -9 20 -20 27 -11 7 -20 19 -20 26 0 6 -9 20 -20 29 -11 10 -20 23 -20 30
0 7 -4 13 -10 13 -5 0 -10 9 -10 20 0 11 -7 20 -15 20 -9 0 -15 9 -15 25 0 13
-7 28 -17 33 -9 5 -17 19 -18 32 -1 12 -7 25 -15 30 -7 4 -10 15 -7 23 3 9 -1
18 -9 21 -9 3 -13 10 -9 15 3 5 1 12 -5 16 -5 3 -10 16 -10 29 0 12 -5 27 -12
34 -9 9 -9 12 1 12 11 0 11 3 1 15 -6 8 -9 19 -5 25 3 5 -1 10 -9 10 -9 0 -16
7 -16 15 0 8 5 15 11 15 8 0 8 5 -1 15 -7 8 -10 22 -6 30 3 8 1 15 -3 15 -10
0 -13 30 -13 135 0 121 4 147 35 270 53 207 149 370 307 518 138 131 324 227
515 268 93 20 301 24 391 8z"/>
					<path d="M5371 4790 c-38 -120 -90 -236 -149 -334 -43 -70 -48 -82 -34 -88 4
-1 34 -34 66 -71 194 -225 492 -398 813 -473 133 -31 388 -43 526 -25 263 35
520 140 729 296 91 69 227 198 274 261 16 22 32 41 35 44 4 3 30 41 58 84 59
91 60 88 -39 139 -30 16 -109 60 -175 98 -66 38 -125 69 -131 69 -6 0 -15 -10
-21 -22 -19 -42 -107 -152 -173 -216 -269 -262 -678 -365 -1035 -261 -38 11
-94 31 -123 45 -29 13 -56 24 -60 24 -10 0 -149 88 -198 125 -102 78 -233 238
-320 390 -8 15 -16 1 -43 -85z"/>
					<path d="M6613 3563 l-103 -4 0 -189 0 -190 165 0 165 0 0 45 0 45 -100 0
c-92 0 -100 2 -100 19 0 11 -3 26 -6 35 -5 13 8 16 88 18 l93 3 3 37 3 37 -93
3 -93 3 -3 28 -3 27 100 0 101 0 0 45 0 45 -57 -2 c-32 -1 -104 -4 -160 -5z"/>
					<path d="M7042 3553 c-60 -29 -85 -74 -89 -161 -4 -66 -1 -83 19 -121 34 -65
66 -85 147 -89 84 -5 132 10 166 49 26 32 42 75 31 86 -3 3 -26 9 -51 13 -43
7 -46 6 -65 -27 -16 -27 -25 -33 -54 -33 -53 0 -74 28 -75 103 0 50 3 65 21
84 30 32 80 31 105 -1 16 -22 22 -24 61 -17 23 5 46 11 49 15 10 10 -27 75
-55 97 -32 25 -161 27 -210 2z"/>
					<path d="M7417 3563 c-4 -3 -7 -26 -7 -50 l0 -43 60 0 60 0 0 -140 0 -140 33
-1 c17 0 44 -2 60 -4 l27 -4 0 144 0 144 58 3 57 3 3 48 3 47 -174 0 c-95 0
-177 -3 -180 -7z"/>
					<path d="M4629 3550 c-43 -13 -74 -38 -96 -77 -70 -126 -11 -290 110 -308 65
-10 141 0 171 22 25 18 66 82 66 101 0 11 -61 32 -93 32 -10 0 -22 -11 -27
-24 -19 -50 -69 -59 -105 -20 -21 22 -25 37 -25 84 0 41 5 64 18 81 26 31 78
31 104 -1 16 -20 24 -22 65 -16 25 3 49 9 52 12 11 11 -35 79 -68 101 -36 24
-116 30 -172 13z"/>
					<path d="M5101 3549 c-78 -31 -121 -97 -121 -189 0 -75 28 -132 81 -167 38
-25 52 -28 118 -28 65 1 82 5 118 28 56 36 75 76 81 168 4 61 1 82 -14 105
-24 39 -53 67 -87 82 -32 14 -141 15 -176 1z m126 -103 c25 -21 28 -31 28 -84
0 -72 -21 -102 -70 -102 -26 0 -86 29 -62 30 5 0 1 10 -7 22 -19 26 -20 49 -5
101 16 59 69 74 116 33z"/>
					<path d="M5518 3553 c-16 -4 -18 -22 -18 -194 l0 -190 58 3 57 3 -1 98 c-1 54
1 96 3 93 3 -3 36 -47 72 -98 l67 -93 57 -3 57 -3 0 196 0 196 -57 -3 -58 -3
-5 -100 -5 -100 -19 35 c-10 19 -39 65 -63 103 -44 66 -45 67 -86 66 -23 -1
-50 -3 -59 -6z"/>
					<path d="M6008 3553 c-2 -5 -1 -91 2 -193 l5 -185 53 -3 53 -3 -3 100 c-2 56
-1 101 2 101 3 0 36 -43 73 -95 61 -85 71 -95 100 -96 18 -1 43 -2 57 -3 l25
-1 3 193 2 192 -60 0 -60 0 0 -102 c-1 -115 -4 -116 -57 -13 -14 27 -37 64
-52 82 -24 30 -31 33 -83 33 -31 0 -58 -3 -60 -7z"/>
					<path d="M3084 3543 l-41 -4 -66 -177 c-36 -97 -67 -184 -69 -192 -2 -12 9
-15 56 -15 59 0 60 0 68 33 9 32 9 32 73 32 65 0 65 0 79 -35 15 -34 17 -35
69 -35 29 0 56 3 59 6 8 7 -20 126 -32 138 -4 4 -6 15 -3 22 3 8 1 14 -4 14
-10 0 -37 65 -38 90 -1 8 -13 41 -28 73 -30 62 -23 59 -123 50z m42 -178 c10
-30 15 -57 12 -60 -3 -3 -17 -3 -32 1 -24 6 -25 9 -20 48 12 83 17 85 40 11z"/>
					<path d="M3479 3534 c-42 -17 -69 -59 -69 -106 1 -55 39 -86 130 -108 63 -15
94 -38 84 -63 -11 -29 -49 -31 -83 -6 -17 12 -28 26 -25 31 6 10 -97 11 -113
0 -20 -13 20 -88 57 -107 44 -23 126 -30 179 -15 83 22 130 104 98 169 -11 22
-12 31 -3 35 6 2 2 5 -9 5 -12 1 -25 5 -30 10 -6 5 -47 19 -93 31 -64 18 -82
26 -80 39 2 10 16 17 40 19 29 3 38 -1 43 -17 5 -18 15 -21 66 -21 65 0 71 8
44 60 -11 22 -12 30 -2 34 6 4 3 5 -9 2 -11 -2 -29 3 -39 10 -25 19 -140 17
-186 -2z"/>
					<path d="M3853 3543 c-13 -2 -23 -8 -23 -12 0 -4 19 -34 43 -67 93 -127 97
-136 97 -224 l0 -80 60 0 60 0 0 73 c0 72 1 76 55 161 29 48 63 98 75 113 11
14 20 30 20 34 0 5 -29 9 -64 9 l-65 0 -36 -60 c-20 -33 -40 -60 -45 -60 -11
0 -25 40 -21 60 2 8 -1 12 -5 8 -4 -5 -15 6 -25 22 -16 26 -24 30 -61 29 -24
-1 -53 -4 -65 -6z"/>
					<path d="M2513 3533 c-23 -4 -23 -5 -23 -194 l0 -189 163 2 162 3 0 45 0 45
-99 -4 c-96 -3 -99 -3 -93 18 3 12 1 21 -4 21 -5 0 -9 7 -9 15 0 11 19 15 93
17 l92 3 3 38 c4 45 2 45 -103 49 -74 3 -80 5 -83 26 -3 22 -1 22 97 22 l101
0 0 29 c0 16 3 36 6 45 5 14 -10 16 -137 15 -79 -1 -154 -4 -166 -6z"/>
				</g>
			</svg>
		</Box >
	);
	if (disabledLink) {
		return <>{logo}</>;
	}

	return <RouterLink to="">{logo}</RouterLink>;
}
